import { Link } from 'react-scroll';
import DataTableComponent from './DataTable';
import { Image } from 'primereact/image';

function App() {
    const team = [
        {
            name: 'Joseph Marsilla',
            role: 'PhD Student',
            link: 'https://www.linkedin.com/in/joseph-marsilla/'
        },
        {
            name: 'Mattea Welch',
            role: 'Staff Scientist',
            link: 'https://www.linkedin.com/in/mattea-welch/'
        },
        {
            name: 'Joshua Siraj',
            role: 'Research Assistant',
            link: 'https://www.linkedin.com/in/joshua-siraj'
        },
        {
            name: 'Denis Tkatchuk',
            role: 'Software developer',
            link: 'https://www.linkedin.com/in/denis-tkachuk-1b2577113'
        },
        {
            name: 'Sejin Kim',
            role: 'PhD Student',
            link: 'https://www.linkedin.com/in/sejinkim3'
        },
        {
            name: 'Tirth Patel',
            role: 'Software Engineer',
            link: 'https://www.linkedin.com/in/tirupatel/'
        },
        {
            name: 'Jun Won Kim',
            role: 'Associate Professor',
            link: ''
        },
        {
            name: 'Tony Tadic',
            role: 'Associate Professor',
            link: 'https://radonc.utoronto.ca/faculty/tony-tadic'
        },
        {
            name: 'Scott Bratman',
            role: 'Associate Professor',
            link: 'https://medbio.utoronto.ca/faculty/bratman'
        },
        {
            name: 'Andrew Hope',
            role: 'Associate Professor',
            link: 'https://radonc.utoronto.ca/faculty/andrew-hope'
        },
        {
            name: 'Benjamin Haibe-Kains',
            role: 'Professor',
            link: 'https://medbio.utoronto.ca/faculty/haibe-kains'
        }
    ];
    return (
        <div className="mt-20 md:mt-4 mx-40 lg:mx-20 md:mx-0 flex flex-col justify-center items-center">
            <div id="main" className="py-10 flex flex-col justify-center items-center">
                <div className="flex flex-col items-center">
                    <h1 className="font-bold text-9xl md:text-7xl text-gray-800 text-center mb-2">SCARF</h1>
                    <h2 className="font-bold text-4xl md:text-2xl text-gray-700 text-center">
                        auto-Segmentation Clinical Acceptability & Reproducibility Framework
                    </h2>
                </div>
                <div
                    id="authors"
                    className="flex flex-row sm:flex-col flex-wrap justify-center items-center gap-4 mt-10 mb-16 px-32 xl:px-10 md:px-2"
                >
                    {team.map(member => (
                        <a
                            className="border-2 border-gray-300 rounded-xl w-60 hover:scale-110 transform transition duration-300 ease-in-out hover:bg-gray-200"
                            href={member.link}
                            target="_blank"
                            rel="noreferrer"
                            key={member.name}
                        >
                            <div className="p-3 text-center ">
                                <h2 className="text-xl md:text-md font-semibold mb-2">{member.name}</h2>
                                <h3 className="text-md md:text-xs text-gray-600">{member.role}</h3>
                            </div>
                        </a>
                    ))}
                </div>

                <div id="paper-and-code" className="flex flex-row flex-wrap gap-6 justify-center items-center">
                    <a
                        className="flex items-center justify-center px-7 py-3 md:p-3 text-2xl md:text-lg font-bold text-black hover:text-black hover:scale-105 transition duration-150 hover:bg-gray-200 border-2 border-gray-300 rounded-full w-40"
                        href="https://www.medrxiv.org/content/10.1101/2022.01.15.22269276v2"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src="/images/logos/paper.png" alt="repository" className="md:w-6 w-8 mr-2" />
                        Paper
                    </a>
                    <a
                        className="flex items-center justify-center px-7 py-3 md:p-3 text-2xl md:text-lg font-bold text-black hover:text-black hover:scale-105 transition duration-150 hover:bg-gray-200 border-2 border-gray-300 rounded-full w-40"
                        href="https://github.com/bhklab/SCARF"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src="/images/logos/github.svg" alt="repository" className="md:w-6 w-8 mr-2" />
                        Code
                    </a>
                    <a
                        className="flex items-center justify-center px-7 py-3 md:p-3 text-2xl md:text-lg font-bold text-black hover:text-black hover:scale-105 transition duration-150 hover:bg-gray-200 border-2 border-gray-300 rounded-full w-40"
                        href="https://colab.research.google.com/drive/1YjbnqRCKdaTnEg3xdKyo2bzSRpMNoQ8I?usp=sharing"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src="/images/logos/colab.png" alt="repository" className="md:w-6 w-8 mr-2" />
                        Collab
                    </a>

                    <Link
                        className="flex items-center justify-center px-7 py-3 md:p-3 text-2xl md:text-lg font-bold text-black hover:text-black hover:scale-105 transition duration-150 hover:bg-gray-200 border-2 border-gray-300 rounded-full w-40 cursor-pointer"
                        to="data"
                        smooth={true}
                        duration={1000}
                        offset={-75}
                    >
                        <img src="/images/logos/data.png" alt="repository" className="w-6 md:w-8 mr-2" />
                        Data
                    </Link>
                </div>
                <div id="paper-and-code" className="flex flex-row flex-wrap gap-6 justify-center items-center mt-6">
                    <a
                        className="flex items-center justify-center px-7 py-3 md:p-3 text-2xl md:text-lg font-bold text-black hover:text-black hover:scale-105 transition duration-150 hover:bg-gray-200 border-2 border-gray-300 rounded-full w-54"
                        href="https://github.com/bhklab/med-imagetools"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src="/images/logos/application.png" alt="repository" className="w-6 md:w-8 mr-2" />
                        Med-ImageTools
                    </a>
                    <a
                        className="flex items-center justify-center px-7 py-3 md:p-3 text-2xl md:text-lg font-bold text-black hover:text-black hover:scale-105 transition duration-150 hover:bg-gray-200 border-2 border-gray-300 rounded-full w-54"
                        href="https://github.com/bhklab/QUANNOTATE"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src="/images/logos/development.png" alt="repository" className="w-6 md:w-8 mr-2" />
                        QUANNOTATE
                    </a>
                </div>
            </div>

            <div id="overview" className="px-32 lg:px-16 md:px-6 py-10">
                <div className="flex flex-col items-center">
                    <h1 className="text-center font-bold text-5xl md:text-3xl text-gray-800 mb-8">Overview</h1>
                    <p className="md:text-left text-center text-gray-700 text-lg md:text-sm">
                        In this study, 582 patients meeting selection criteria with 19 delineated OARs underwent data extraction for
                        training 11 open-source 3D segmentation networks. These networks were chosen from a subset of 60 studies, excluding
                        29 due to code unavailability and 20 for computational or architectural constraints. Performance ranking on a test
                        set of 59 patients across all OARs determined the top model, subsequently fine-tuned and evaluated by 4 expert
                        radiation oncologists in a blinded clinical acceptability assessment on the Quannotate platform.
                    </p>
                </div>
            </div>

            <div id="abstract" className="px-32 lg:px-16 md:px-6 py-10">
                <div className="flex flex-col items-center">
                    <h1 className="text-center font-bold text-5xl md:text-3xl text-gray-800 mb-8">Abstract</h1>
                    <div className="w-full items-left text-gray-700 text-lg md:text-sm">
                        <div className="mb-4">
                            <p>
                                <span className="font-bold">Purpose: </span>
                                Auto-segmentation of organs at risk (OAR) in cancer patients is essential for enhancing radiotherapy
                                planning efficacy and reducing inter-observer variability. Deep learning auto-segmentation models have shown
                                promise, but their lack of transparency and reproducibility hinders their generalizability and clinical
                                acceptability, limiting their use in clinical settings.
                            </p>
                        </div>
                        <div className="mb-4">
                            <p>
                                <span className="font-bold">Methods: </span>
                                This study introduces SCARF (auto-Segmentation Clinical Acceptability & Reproducibility Framework), a
                                comprehensive framework designed to benchmark open-source convolutional neural networks for
                                auto-segmentation of 19 essential OARs in head and neck cancer (HNC). Reproducibility of methods is achieved
                                through common data curation, and model training and assessment steps. New methods can be benchmarked
                                against 11 pre-trained open-source deep learning models, while assessing estimates of clinical acceptability
                                using a k-Nearest Neighbors classifier.
                            </p>
                        </div>
                        <div className="mb-4">
                            <p>
                                <span className="font-bold">Results: </span>
                                The SCARF framework code base is openly-available for OAR auto-segmentation benchmarking. During
                                development, expert assessment of the best performing model labelled 16/19 AI-generated OAR categories as
                                clinically acceptable with minor revisions. Additionally, boundary distance metrics, such as 95th Percentile
                                Hausdorff Distance (95HD), were found to be 2x more correlated to Mean Acceptability Rating (MAR) than
                                volumetric overlap metrics (DICE). These metrics, when used in model clinical acceptability with a k-Nearest
                                Neighbors classifier, achieved an area under the receiver operating characteristic curve of 0.80.
                            </p>
                        </div>
                        <div className="mb-4">
                            <p>
                                <span className="font-bold">Conclusions: </span>
                                SCARF facilitates benchmarking and expert assessment of AI-driven auto-segmentation tools, addressing the
                                need for transparency and reproducibility in this domain. Through its emphasis on clinical acceptability,
                                SCARF fosters the integration of AI models into clinical environments, specifically within radiation
                                therapy, paving the way for randomised clinical trials to evaluate their real-world impact.
                            </p>
                        </div>
                        <div className="text-lg md:text-sm">
                            <div className="font-bold mb-2">Highlights: </div>
                            <ul className="list-disc pl-8">
                                <li>
                                    <p>
                                        SCARF is a comprehensive and reproducible six-stage framework for advancing auto-segmentation
                                        research and contributing to the foundation of AI tools in radiation therapy planning.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        SCARF enables benchmarking of models against 11 open-source convolutional neural networks for 19
                                        essential organs-at-risk contoured in radiation therapy in head and neck cancer.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        We emphasize the importance of clinical acceptability testing and introduce a clinical assessment
                                        toolkit comprised of a surrogate modeling function and an open-source rating platform.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div id="methods" className="px-32 lg:px-16 md:px-6 py-10">
                <div className="flex flex-col items-center">
                    <h1 className="text-center font-bold text-5xl md:text-3xl text-gray-800 mb-8">Methods</h1>
                    <p className="mb-6 text-lg md:text-sm">
                        <span className="font-bold">Reproducibility: </span>
                        The first half of the cycle emphasizes reproducible development of code and software used during dataset curation,
                        model selection and model training stages.
                        <br />
                        <br />
                        <span className="font-bold">Acceptability: </span>
                        In the second half of the cycle, emphasis is placed on development of an acceptability standard, that uses
                        “clinician focused” evaluation protocols to identify relevant performance metrics and build quality assurance tools
                        that focus on recording data that will aid model optimization to maximize expert approval ratings.
                    </p>
                    <Image
                        src="/images/section-images/methods.jpg"
                        className="w-2/3 h-auto border-2 border-gray-300 rounded-lg"
                        alt="Methods"
                        preview
                    />
                </div>
            </div>

            <div id="results" className="px-32 lg:px-16 md:px-6 pt-16 md:pt-10">
                <div className="flex flex-col items-center">
                    <h1 className="text-center font-bold text-5xl md:text-3xl text-gray-800 mb-8">Results</h1>
                    <div className="mb-8 items-center">
                        <p className="text-lg md:text-sm">
                            <span className="font-bold">Performance Assessment: </span>
                            Eleven open-source networks were selected based on availability, complexity and adaptability. Model(s) were each
                            trained with 523 patient scans for 3 days on 4xTesla P100 GPU(s) or until convergence using PyTorch-lighting.
                            Barplots of classical metrics (DICE and 95HD) plotted for all OAR(s) for each model when applied to a hold out
                            set of 59 scans. Average values of Brainstem (BSTEM), Larynx, L Brachial-Plexus, and Optic Chiasm are plotted
                            for each model. One simple 3D UNET architecture performed best across all OAR(s) segmented for both metrics.
                            This network (labeled WOLNET, after its author) was chosen for retraining and use in clinical acceptability
                            testing.
                        </p>
                    </div>
                    <Image
                        src="/images/section-images/results-1.jpg"
                        className="w-2/3 h-auto border-2 border-gray-300 rounded-lg mb-12"
                        alt="performance"
                        preview
                    />
                    <div className="mb-8">
                        <p className="text-lg md:text-sm">
                            <span className="font-bold">Clinical Assessment: </span>
                            Results of the acceptability test by representing mean acceptability rating (MAR) counts for each OAR in a
                            heat-map for (A) Ground-Truth contours (GT) and (B) AI-generate contours (AI). The higher the value of a box the
                            more contours of that given OAR (row) had any given MAR value (column) and the lighter that box will be. Notice
                            a shift to the left when examining the heat-map of mean acceptability ratings for deep learning contours
                            examined for each OAR indicating a greater degree of clinical acceptance for manual contours as depicted by
                            figure 4D. GT contours received a significantly higher mean rating of 3.75 than AI contours which were rated
                            3.23 when all OARs were considered (3.75 ± 0.77 vs. 3.23 ± 0.86, p &lt; 0.01).
                        </p>
                    </div>
                    <Image
                        src="/images/section-images/results-2.png"
                        className="w-2/3 h-auto border-2 border-gray-300 rounded-lg mb-12"
                        alt="clinical"
                        preview
                    />
                    <div className="mb-8">
                        <p className="text-lg md:text-sm">
                            <span className="font-bold">Generalizability Assessment: </span>
                            Beeplot to show variation of 3D Volumetric DICE performance of WOLNET ensemble for select OARs (L/R Acoustics,
                            Larynx, Oesophagus, L/R Lens, Spinal Cord, L/R Parotids) across different datasets. Broad spectrum in contouring
                            protocols of the acoustics across different centers.
                        </p>
                    </div>
                    <Image
                        src="/images/section-images/generalizability-assessment.jpg"
                        className="w-2/3 h-auto border-2 border-gray-300 rounded-lg"
                        alt="generalizability"
                        preview
                    />
                </div>
            </div>

            <div id="data" className="px-32 lg:px-16 md:px-6 pt-16 md:pt-10">
                <div className="flex flex-col sm:items-center">
                    <h1 className="text-center font-bold text-5xl md:text-3xl text-gray-800 mb-8">Data Tables</h1>
                    {/* <div className="mb-8 text-center text-lg md:text-sm">
                        <span className="font-bold">External HNC imaging and segmentation datasets</span>
                    </div> */}
                </div>
            </div>
            <div className="w-full sm:w-3/4 md:w-3/4 lg:w-5/6 xl:w-5/6 overflow-x-auto">
                <p className="text-sm md:text-xs break-words font-bold text-red-800 mb-3">
                    **Missing data are represented in gray. 'Available Data' types that are only available for a portion of patients are
                    represented by a transparently coloured cell**
                </p>
                <DataTableComponent className="w-full" />
            </div>
            <div id="citation" className="px-32 sm:px-4 py-10 mb-40 sm:mb-10">
                <div className="flex flex-col items-center">
                    <h1 className="text-center font-bold text-5xl md:text-3xl text-gray-800 mb-8">Citation</h1>
                    <div className="w-full sm:w-1/2 md:w-3/4 lg:w-5/6 xl:w-6/6 overflow-x-auto px-2 py-2 bg-orange-100/50 rounded-lg border-2 border-orange-100/90 text-wrap">
                        <p className="font-mono sm:font-base md:text-xs">
                            {
                                'author = {Marsilla, Joseph and Won Kim, Jun and Kim, Sejin and Tkachuck, Denis and Rey-McIntyre, Katrina and Patel, Tirth and Tadic, Tony and Liu, Fei-Fei and Bratman, Scott and Hope, Andrew and Haibe-Kains, Benjamin},'
                            }
                            <br />
                            {
                                'title = {Evaluating clinical acceptability of organ-at-risk segmentation In head & neck cancer using a compendium of open-source 3D convolutional neural networks},'
                            }
                            <br />
                            {'elocation-id = {2022.01.15.22269276},'}
                            <br />
                            {'year = {2022},'}
                            <br />
                            {'doi = {10.1101/2022.01.15.22269276},'}
                            <br />
                            {'publisher = {Cold Spring Harbor Laboratory Press},'}
                            <br />
                            {'URL = {https://www.medrxiv.org/content/early/2022/01/25/2022.01.15.22269276},'}
                            <br />
                            {'eprint = {https://www.medrxiv.org/content/early/2022/01/25/2022.01.15.22269276.full.pdf},'}
                            <br />
                            {'journal = {medRxiv}'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
