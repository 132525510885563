import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './main.css';
import Navbar from './Navbar';
import Footer from './Footer'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Navbar/>
    <App />
    <Footer />
  </React.StrictMode>
);