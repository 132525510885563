import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Link } from 'react-scroll';

const Navbar = () => {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <div
                className="fixed top-0 z-10 w-full bg-white md:bg-transparent shadow-md md:shadow-none
					flex flex-row justify-center items-center md:justify-start
					md:items-left mx-auto py-4 px-72 md:px-0 text-md md:text-lg text-black-900"
            >
                {/* Mobile button */}
                <Button
                    icon="pi pi-bars"
                    className="hidden md:block"
                    onClick={() => setVisible(true)}
                    aria-controls="sidebar"
                    aria-haspopup
                />

                {/* Desktop Links */}
                <Link
                    key="overview"
                    to="overview"
                    className="hover:text-gray-600 transition duration-300 px-3 py-2 cursor-pointer md:hidden"
                    smooth={true}
                    duration={1000}
                    offset={-50}
                >
                    <span className="self-center font-semibold whitespace-nowrap">Overview</span>
                </Link>
                <Link
                    key="abstract"
                    to="abstract"
                    className="hover:text-gray-600 transition duration-300 px-3 py-2 cursor-pointer md:hidden"
                    smooth={true}
                    duration={1000}
                    offset={-50}
                >
                    <span className="self-center font-semibold whitespace-nowrap">Abstract</span>
                </Link>
                <Link
                    key="methods"
                    to="methods"
                    className="hover:text-gray-600 transition duration-300 px-3 py-2 cursor-pointer md:hidden"
                    smooth={true}
                    duration={1000}
                    offset={-50}
                >
                    <span className="self-center font-semibold whitespace-nowrap">Methods</span>
                </Link>
                <Link
                    key="results"
                    to="results"
                    className="hover:text-gray-600 transition duration-300 px-3 py-2 cursor-pointer md:hidden"
                    smooth={true}
                    duration={1000}
                    offset={-50}
                >
                    <span className="self-center font-semibold whitespace-nowrap">Results</span>
                </Link>
                <Link
                    key="data"
                    to="data"
                    className="hover:text-gray-600 transition duration-300 px-3 py-2 cursor-pointer md:hidden"
                    smooth={true}
                    duration={1000}
                    offset={-50}
                >
                    <span className="self-center font-semibold whitespace-nowrap">Data</span>
                </Link>
                <Link
                    key="citation"
                    to="citation"
                    className="hover:text-gray-600 transition duration-300 px-3 py-2 cursor-pointer md:hidden"
                    smooth={true}
                    duration={1000}
                    offset={-50}
                >
                    <span className="self-center font-semibold whitespace-nowrap">Citation</span>
                </Link>
            </div>

            {/* Mobile Sidebar Links*/}
            <Sidebar visible={visible} onHide={() => setVisible(false)} modal showCloseIcon={false} position="left">
                <a
                    href="#overview"
                    className="block text-black-900 hover:bg-gray-200 transition duration-300 px-3 py-2"
                    onClick={() => setVisible(false)}
                >
                    Overview
                </a>
                <a
                    href="#abstract"
                    className="block text-black-900 hover:bg-gray-200 transition duration-300 px-3 py-2"
                    onClick={() => setVisible(false)}
                >
                    Abstract
                </a>
                <a
                    href="#methods"
                    className="block text-black-900 hover:bg-gray-200 transition duration-300 px-3 py-2"
                    onClick={() => setVisible(false)}
                >
                    Methods
                </a>
                <a
                    href="#results"
                    className="block text-black-900 hover:bg-gray-200 transition duration-300 px-3 py-2"
                    onClick={() => setVisible(false)}
                >
                    Results
                </a>
                <a
                    href="#data"
                    className="block text-black-900 hover:bg-gray-200 transition duration-300 px-3 py-2"
                    onClick={() => setVisible(false)}
                >
                    Data
                </a>
                <a
                    href="#citation"
                    className="block text-black-900 hover:bg-gray-200 transition duration-300 px-3 py-2"
                    onClick={() => setVisible(false)}
                >
                    Citation
                </a>
            </Sidebar>
        </>
    );
};

export default Navbar;
