import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-gradient-to-r from-gray-700 via-gray-800 to-gray-900 text-white pt-3 pb-4 px-5 relative overflow-hidden">
            <div className="container mx-auto flex flex-col items-center">
                <a href="https://www.bhklab.ca/" target="_blank" rel="noopener noreferrer" className="group mb-4">
                    <div className="transform group-hover:scale-110 transition duration-300 ease-in-out">
                        <img src="/images/bhk-logos/bhklogo.png" alt="SGC Logo" className="w-48" />
                    </div>
                </a>
                <p className="text-sm leading-relaxed max-w-xl text-center mb-6">
                    SCARF: Auto-Segmentation Clinical Acceptability & Reproducibility Framework for Benchmarking Essential Radiation Therapy
                    Targets in Head and Neck Cancer
                </p>
                <hr className="border-t-2 border-gray-500 w-3/4 mx-auto mb-6" />
                <div className="text-center text-xs mb-2">
                    <span className="block mb-2">© {new Date().getFullYear()} Benjamin Haibe-Kains Lab</span>
                </div>
            </div>
            <div className="w-full h-4 bg-gradient-to-r from-transparent via-gray-700 to-transparent absolute bottom-0 left-0"></div>
        </footer>
    );
};

export default Footer;
